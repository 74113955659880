<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <section class="question-section section-gap-30">
                <div class="section-title-wrap">
                    <div class="d-flex justify-content-between">
                        <h2 class="section-title mr-2">{{$t(`All assessments`)}}</h2>
                        <v-popover offset="10" :disabled="false" style="display: initial;">
                            <button class="ej__tooltip__button mlF-1"><i class="eicon e-question"></i></button>
                            <template slot="popover">
                                <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                <a v-close-popover href="https://easy.jobs/docs/how-to-add-an-assessment-in-easy-jobs/#1-toc-title" target="_blank">
                                    {{ $t(`How to create Assessment?`) }}
                                </a>
                            </template>
                        </v-popover>
                    </div>
                    <form action="" class="form-filter mr-auto mb-0 ml-5">
                        <div class="search-bar">
                            <input type="text"
                                   ref="filter_name"
                                   v-model="filter.name"
                                   @keyup="loadQuestionSets"
                                   @keypress.enter.prevent="loadQuestionSets"
                                   :placeholder="$t(`Search`)+`. . .`"
                                   autofocus
                            />
                        </div>
                    </form>
                    <router-link :to="{name: 'company.assessments.create'}" class="edit-button ml-2">
                        <span class="edit-icon"><i class="eicon e-plus"></i></span>
                        <span class="text-capitalize">{{ $t("create assessment")}}</span>
                    </router-link>
                </div>
                <div class="row row-cols-md-4 has-col-gap" v-if="isContentLoading">
                    <div class="col" v-for="index in 8" :key="index">
                        <QuestionSetLoader></QuestionSetLoader>
                    </div>
                </div>
                <template v-else>
                    <h4 class="empty-message" v-if="isEmpty">{{$t(`No assessments found.`)}}</h4>
                    <div class="row row-cols-md-4 has-col-gap" v-if="!isEmpty">
                        <div class="col" v-for="group in questionGroups">
                            <div class="question-card">
                                <ul>
                                    <li class="text-break">{{$t(`assessment name`)}}:
                                        <router-link :to="{name: 'company.assessments.edit', params: {id: group.id}}">{{group.name}}</router-link></li>
                                    <li>{{$t(`Question Type`)}}: <span>{{group.exam_type.name}}</span></li>
                                    <li>{{$t(`Total Question`)}}: <span>{{group.total_questions}}</span></li>
                                    <li>{{$t(`Updated On`)}}: <span>{{group.last_update}}</span></li>
                                </ul>
                                <div class="d-flex mt-auto">
                                    <router-link :to="{name: 'company.assessments.edit', params: {id: group.id}}" class="control-button control-button--primary has_bg_color">
                                        <div class="control-button__icon"><i class="eicon e-pencil"></i></div>
                                        <span>{{$t(`Edit`)}}</span>
                                    </router-link>
                                    <a href="#" @click.prevent="deleteQuestionSet(group.id)" class="control-button control-button--danger has_bg_color">
                                        <div class="control-button__icon"><i class="eicon e-delete"></i></div>
                                        <span>{{$t(`Delete`)}}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

            </section>
        </div>
    </dashboard-layout>
</template>
<script>
    const DashboardLayout = () => import("../../layouts/DashboardLayout.vue");
    import client from '../../app/api/Client';
    const QuestionSetLoader = () => import("../../components/_loaders/QuestionSetLoader.vue");

    export default {
        components: {
            DashboardLayout,
            QuestionSetLoader
        },
        data() {
            return {
                isContentLoading: false,
                questionGroups: [],
                filter: {
                    name: '',
                }
            }
        },
        computed: {
            isEmpty() {
                return this.questionGroups.length === 0;
            }
        },
        methods: {
            async loadQuestionSets() {
                this.isContentLoading = true;
                try {
                    let {data: {data}} = await client().get('/company/assessments', {params: this.filter});
                    this.questionGroups = data;
                } catch (e) {
                }
                this.isContentLoading = false;
            },

            async deleteQuestionSet(id) {
                let message = {
                    title: this.$t("Confirmation"),
                    body: `<div class="custom-delete">
                                <p class="text-center">${this.$t('If you delete assessment, the following things will happen:')}</p>
                                <ul class="small-text text-left" style="font-size: 13px;">
                                    <li>-${this.$t('All previous or pending assigned candidates will be unassigned.')}</li>
                                    <li>-${this.$t('Scores/marks of all participants will be deleted.')}</li>
                                    <li>-${this.$t('Candidates participating in this assessment right now will be stopped.')}</li>
                                </ul>
                         </div>`
                };
                let dialogConfig = {
                    okText: this.$t('yes'),
                    cancelText: this.$t('no'),
                    html: true,
                    verification: 'delete',
                    type: 'hard'
                };
                this.$dialog.confirm(message, dialogConfig).then(() => {
                    client().delete(`/company/assessments/${id}/delete`)
                        .then(({data: {data, message}}) => {
                            this.$toast.success(this.$t(message));
                        })
                        .catch(({response: {data}}) => {
                            this.$toast.error(this.$t(data.message));
                        }).finally(()=> {
                            this.loadQuestionSets();
                    });
                });
            },
        },
        async created() {
            await this.loadQuestionSets();
            await this.$refs.filter_name.focus();
        }
    }
</script>

<style scoped>
.question-card ul {
    list-style: none;
}
</style>